var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('v-btn',{staticStyle:{"width":"100%","height":"5px !important"},attrs:{"outlined":""},on:{"click":_vm.loadFacturas}},[_vm._v(" "+_vm._s(_vm.$t("billing.view_invoices"))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"text-decoration":"underline"}},[_vm._v(" Facturas ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-data-table',{attrs:{"footer-props":{
        'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
          ? ''
          : _vm.$t('items_per_page'),
      },"headers":_vm.headers,"items":_vm.list},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
      var item = ref.item;
return [_c('a',{domProps:{"textContent":_vm._s(item.number)},on:{"click":function($event){return _vm.$router.push({
              name: 'filesView',
              params: { sub_route: 'facturas/stripe' },
            })}}})]}},{key:"item.total",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$n(item.total / 100, "currency"))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"large":""}},on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"lessMargin",on:{"click":function($event){return _vm.dowloadFactura(item.id)}}},[_c('v-list-item-icon',{staticStyle:{"margin-top":"9px","margin-rigth":"10px"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$download")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("download")))])])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }