<template>
  <v-dialog width="800" v-model="dialog">
    <template v-slot:activator="{}">
      <v-btn
        outlined
        style="width: 100%; height: 5px !important"
        @click="loadFacturas"
      >
        {{ $t("billing.view_invoices") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title style="text-decoration: underline"> Facturas </v-card-title>
      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-data-table
        :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :items="list"
      >
        <template v-slot:item.number="{ item }"
          ><a
            @click="
              $router.push({
                name: 'filesView',
                params: { sub_route: 'facturas/stripe' },
              })
            "
            v-text="item.number"
        /></template>
        <template v-slot:item.total="{ item }">
          {{ $n(item.total / 100, "currency") }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" large>mdi-dots-horizontal</v-icon>
            </template>
            <v-list>
              <v-list-item class="lessMargin" @click="dowloadFactura(item.id)">
                <v-list-item-icon style="margin-top: 9px; margin-rigth: 10px">
                  <v-icon small>$download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  <span>{{ $t("download") }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "Facturas",
  data() {
    return {
      dialog: false,
      list: [],
      headers: [
        {
          text: this.$t("inventorys.invoice_number"),
          sortable: false,
          value: "number",
        },
        {
          text: "Total",
          sortable: false,
          value: "total",
        },
        {
          text: this.$t("actions"),
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    ...mapActions("plans", ["getUserInvoices", "getInvoice"]),
    loadFacturas() {
      this.getUserInvoices().then((data) => {
        //console.log(data);
        this.list = data.actual.data;
        this.dialog = true;
      });
    },
    dowloadFactura(id) {
      this.getInvoice(id).then((data) => {
        //console.log("return");
        //console.log(data);
        axios.get(data.url, { responseType: "blob" }).then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "Factura - " + id + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        });
      });
    },
  },
};
</script>

<style lang="sass">
.lessMargin

  .v-list-item__icon
    margin-right: 5px !important
</style>